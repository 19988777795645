// import * as React from "react";
// import { alpha, styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import Button from "@mui/material/Button";
// import Container from "@mui/material/Container";
// import BottomNavigation from "@mui/material/BottomNavigation";
// import BottomNavigationAction from "@mui/material/BottomNavigationAction";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
// import Sitemark from "./SitemarkIcon"; // Import your custom icon here
// import HomeIcon from "@mui/icons-material/Home";
// import PricingIcon from "@mui/icons-material/AttachMoney";
// import BlogIcon from "@mui/icons-material/Article";
// import FAQIcon from "@mui/icons-material/HelpOutline";

// const StyledToolbar = styled(Toolbar)(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "space-between",
//   borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
//   backdropFilter: "blur(12px)",
//   border: "1px solid",
//   borderColor: theme.palette.divider,
//   backgroundColor: alpha(theme.palette.background.default, 0.5),
//   boxShadow: theme.shadows[1],
//   padding: "8px 16px",
// }));

// export default function AppAppBar() {
//   const [value, setValue] = React.useState(0);

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("md"));
//   return (
//     <>
//       {isMobile ? (
//         <BottomNavigation
//           value={value}
//           onChange={(event, newValue) => {
//             setValue(newValue);
//           }}
//           sx={{
//             position: "fixed",
//             bottom: 0,
//             left: 0,
//             right: 0,
//             width: "100%",
//             boxShadow: theme.shadows[4],
//           }}
//         >
//           <BottomNavigationAction label="Home" icon={<HomeIcon />} />
//           <BottomNavigationAction label="FAQ" icon={<FAQIcon />} />
//           <BottomNavigationAction label="Pricing" icon={<PricingIcon />} />
//           <BottomNavigationAction label="Blog" icon={<BlogIcon />} />
//         </BottomNavigation>
//       ) : (
//         <AppBar
//           position="fixed"
//           sx={{
//             boxShadow: 0,
//             bgcolor: "transparent",
//             backgroundImage: "none",
//             mt: 10,
//           }}
//         >
//           <Container maxWidth="lg">
//             <StyledToolbar variant="dense" disableGutters>
//               <Box
//                 sx={{
//                   flexGrow: 1,
//                   display: "flex",
//                   alignItems: "center",
//                   px: 0,
//                 }}
//               >
//                 <Sitemark />
//                 <Box sx={{ display: { xs: "none", md: "flex" } }}>
//                   <Button variant="text" color="info" size="small">
//                     Features
//                   </Button>
//                   <Button variant="text" color="info" size="small">
//                     Products
//                   </Button>
//                   <Button variant="text" color="info" size="small">
//                     Cart
//                   </Button>
//                   <Button variant="text" color="info" size="small">
//                     Aboute Us
//                   </Button>
//                 </Box>
//               </Box>
//               <Box
//                 sx={{
//                   display: { xs: "none", md: "flex" },
//                   gap: 1,
//                   alignItems: "center",
//                 }}
//               >
//                 <Button color="primary" variant="text" size="small">
//                   Sign in
//                 </Button>
//                 <Button color="primary" variant="contained" size="small">
//                   Sign up
//                 </Button>
//               </Box>
//             </StyledToolbar>
//           </Container>
//         </AppBar>
//       )}
//     </>
//   );
// }
import React, { useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Sitemark from "./SitemarkIcon"; // Import your custom icon here
import HomeIcon from "@mui/icons-material/Home";
import PricingIcon from "@mui/icons-material/AttachMoney";
import BlogIcon from "@mui/icons-material/Article";
import FAQIcon from "@mui/icons-material/HelpOutline";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: "blur(12px)",
  border: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.5),
  boxShadow: theme.shadows[1],
  padding: "8px 16px",
}));

export default function AppAppBar() {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isInstallable, setIsInstallable] = useState<boolean>(false);

  // Listen for the beforeinstallprompt event
  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault(); // Prevent automatic prompt
      setDeferredPrompt(e); // Store the event
      setIsInstallable(true); // Enable the install button
    };

    window.addEventListener("beforeinstallprompt", handler);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  // Handle the install button click
  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the install prompt
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === "accepted") {
        console.log("User accepted the install prompt");
        setDeferredPrompt(null); // Reset the deferred prompt state
        setIsInstallable(false); // Hide the install button
      } else {
        console.log("User dismissed the install prompt");
      }
    }
  };

  return (
    <>
      {isMobile ? (
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            boxShadow: theme.shadows[4],
          }}
        >
          <BottomNavigationAction label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction label="FAQ" icon={<FAQIcon />} />
          <BottomNavigationAction label="Pricing" icon={<PricingIcon />} />
          <BottomNavigationAction label="Blog" icon={<BlogIcon />} />
        </BottomNavigation>
      ) : (
        <AppBar
          position="fixed"
          sx={{
            boxShadow: 0,
            bgcolor: "transparent",
            backgroundImage: "none",
            mt: 10,
          }}
        >
          <Container maxWidth="lg">
            <StyledToolbar variant="dense" disableGutters>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  px: 0,
                }}
              >
                <Sitemark />
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <Button variant="text" color="info" size="small">
                    Features
                  </Button>
                  <Button variant="text" color="info" size="small">
                    Products
                  </Button>
                  <Button variant="text" color="info" size="small">
                    Cart
                  </Button>
                  <Button variant="text" color="info" size="small">
                    About Us
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Button color="primary" variant="text" size="small">
                  Sign in
                </Button>
                <Button color="primary" variant="contained" size="small">
                  Sign up
                </Button>
                {/* Add "Download App" button, shown if the prompt is available */}
                {/* {installPrompt && ( */}
                <Button
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleInstallClick}
                >
                  Download App
                </Button>
                {/* )} */}
              </Box>
            </StyledToolbar>
          </Container>
        </AppBar>
      )}
    </>
  );
}
