// import * as React from "react";
// import Avatar from "@mui/material/Avatar";
// import AvatarGroup from "@mui/material/AvatarGroup";
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid2";
// import Pagination from "@mui/material/Pagination";
// import Typography from "@mui/material/Typography";
// import { styled } from "@mui/material/styles";
// import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

// const articleInfo = [
//   {
//     tag: "Engineering",
//     title: "The future of AI in software engineering",
//     description:
//       "Artificial intelligence is revolutionizing software engineering. Explore how AI-driven tools are enhancing development processes and improving software quality.",
//     authors: [
//       { name: "Remy Sharp", avatar: "/static/images/avatar/1.jpg" },
//       { name: "Travis Howard", avatar: "/static/images/avatar/2.jpg" },
//     ],
//   },
//   {
//     tag: "Product",
//     title: "Driving growth with user-centric product design",
//     description:
//       "Our user-centric product design approach is driving significant growth. Learn about the strategies we employ to create products that resonate with users.",
//     authors: [{ name: "Erica Johns", avatar: "/static/images/avatar/6.jpg" }],
//   },
//   {
//     tag: "Design",
//     title: "Embracing minimalism in modern design",
//     description:
//       "Minimalism is a key trend in modern design. Discover how our design team incorporates minimalist principles to create clean and impactful user experiences.",
//     authors: [{ name: "Kate Morrison", avatar: "/static/images/avatar/7.jpg" }],
//   },
//   {
//     tag: "Company",
//     title: "Cultivating a culture of innovation",
//     description:
//       "Innovation is at the heart of our company culture. Learn about the initiatives we have in place to foster creativity and drive groundbreaking solutions.",
//     authors: [{ name: "Cindy Baker", avatar: "/static/images/avatar/3.jpg" }],
//   },
//   {
//     tag: "Engineering",
//     title: "Advancing cybersecurity with next-gen solutions",
//     description:
//       "Our next-generation cybersecurity solutions are setting new standards in the industry. Discover how we protect our clients from evolving cyber threats.",
//     authors: [
//       { name: "Agnes Walker", avatar: "/static/images/avatar/4.jpg" },
//       { name: "Trevor Henderson", avatar: "/static/images/avatar/5.jpg" },
//     ],
//   },
//   {
//     tag: "Product",
//     title: "Enhancing customer experience through innovation",
//     description:
//       "Our innovative approaches are enhancing customer experience. Learn about the new features and improvements that are delighting our users.",
//     authors: [{ name: "Travis Howard", avatar: "/static/images/avatar/2.jpg" }],
//   },
//   {
//     tag: "Engineering",
//     title: "Pioneering sustainable engineering solutions",
//     description:
//       "Learn about our commitment to sustainability and the innovative engineering solutions we're implementing to create a greener future. Discover the impact of our eco-friendly initiatives.",
//     authors: [
//       { name: "Agnes Walker", avatar: "/static/images/avatar/4.jpg" },
//       { name: "Trevor Henderson", avatar: "/static/images/avatar/5.jpg" },
//     ],
//   },
//   {
//     tag: "Product",
//     title: "Maximizing efficiency with our latest product updates",
//     description:
//       "Our recent product updates are designed to help you maximize efficiency and achieve more. Get a detailed overview of the new features and improvements that can elevate your workflow.",
//     authors: [{ name: "Travis Howard", avatar: "/static/images/avatar/2.jpg" }],
//   },
//   {
//     tag: "Design",
//     title: "Designing for the future: trends and insights",
//     description:
//       "Stay ahead of the curve with the latest design trends and insights. Our design team shares their expertise on creating intuitive and visually stunning user experiences.",
//     authors: [{ name: "Kate Morrison", avatar: "/static/images/avatar/7.jpg" }],
//   },
//   {
//     tag: "Company",
//     title: "Our company's journey: milestones and achievements",
//     description:
//       "Take a look at our company's journey and the milestones we've achieved along the way. From humble beginnings to industry leader, discover our story of growth and success.",
//     authors: [{ name: "Cindy Baker", avatar: "/static/images/avatar/3.jpg" }],
//   },
// ];

// const StyledTypography = styled(Typography)({
//   display: "-webkit-box",
//   WebkitBoxOrient: "vertical",
//   WebkitLineClamp: 2,
//   overflow: "hidden",
//   textOverflow: "ellipsis",
// });

// const TitleTypography = styled(Typography)(({ theme }) => ({
//   position: "relative",
//   textDecoration: "none",
//   "&:hover": { cursor: "pointer" },
//   "& .arrow": {
//     visibility: "hidden",
//     position: "absolute",
//     right: 0,
//     top: "50%",
//     transform: "translateY(-50%)",
//   },
//   "&:hover .arrow": {
//     visibility: "visible",
//     opacity: 0.7,
//   },
//   "&:focus-visible": {
//     outline: "3px solid",
//     outlineColor: "hsla(210, 98%, 48%, 0.5)",
//     outlineOffset: "3px",
//     borderRadius: "8px",
//   },
//   "&::before": {
//     content: '""',
//     position: "absolute",
//     width: 0,
//     height: "1px",
//     bottom: 0,
//     left: 0,
//     backgroundColor: theme.palette.text.primary,
//     opacity: 0.3,
//     transition: "width 0.3s ease, opacity 0.3s ease",
//   },
//   "&:hover::before": {
//     width: "100%",
//   },
// }));

// function Author({ authors }: { authors: { name: string; avatar: string }[] }) {
//   return (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "row",
//         gap: 2,
//         alignItems: "center",
//         justifyContent: "space-between",
//       }}
//     >
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "row",
//           gap: 1,
//           alignItems: "center",
//         }}
//       >
//         <AvatarGroup max={3}>
//           {authors.map((author, index) => (
//             <Avatar
//               key={index}
//               alt={author.name}
//               src={author.avatar}
//               sx={{ width: 24, height: 24 }}
//             />
//           ))}
//         </AvatarGroup>
//         <Typography variant="caption">
//           {authors.map((author) => author.name).join(", ")}
//         </Typography>
//       </Box>
//       <Typography variant="caption">July 14, 2021</Typography>
//     </Box>
//   );
// }

// export default function Latest() {
//   const [focusedCardIndex, setFocusedCardIndex] = React.useState<number | null>(
//     null
//   );

//   const handleFocus = (index: number) => {
//     setFocusedCardIndex(index);
//   };

//   const handleBlur = () => {
//     setFocusedCardIndex(null);
//   };

//   return (
//     <div>
//       <Typography variant="h2" gutterBottom>
//         Latest
//       </Typography>
//       <Grid container spacing={8} columns={12} sx={{ my: 4 }}>
//         {articleInfo.map((article, index) => (
//           <Grid key={index} size={{ xs: 12, sm: 6 }}>
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 gap: 1,
//                 height: "100%",
//               }}
//             >
//               <Typography gutterBottom variant="caption" component="div">
//                 {article.tag}
//               </Typography>
//               <TitleTypography
//                 gutterBottom
//                 variant="h6"
//                 onFocus={() => handleFocus(index)}
//                 onBlur={handleBlur}
//                 tabIndex={0}
//                 className={focusedCardIndex === index ? "Mui-focused" : ""}
//               >
//                 {article.title}
//                 <NavigateNextRoundedIcon
//                   className="arrow"
//                   sx={{ fontSize: "1rem" }}
//                 />
//               </TitleTypography>
//               <StyledTypography
//                 variant="body2"
//                 color="text.secondary"
//                 gutterBottom
//               >
//                 {article.description}
//               </StyledTypography>

//               <Author authors={article.authors} />
//             </Box>
//           </Grid>
//         ))}
//       </Grid>
//       <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
//         <Pagination
//           hidePrevButton
//           hideNextButton
//           count={10}
//           boundaryCount={10}
//         />
//       </Box>
//     </div>
//   );
// }
import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

const productInfo = [
  {
    category: "Electronics",
    title: "Smartphone X1",
    description:
      "A sleek, powerful smartphone with an impressive display and long battery life. Perfect for everyday use.",
    price: "$999",
    image: "https://picsum.photos/800/450?random=23",
    stock: 10,
  },
  {
    category: "Appliances",
    title: "Blender 3000",
    description:
      "A powerful kitchen blender that makes cooking easier. Blend smoothies, sauces, and more with ease.",
    price: "$120",
    image: "https://picsum.photos/800/450?random=10",
    stock: 5,
  },
  {
    category: "Fashion",
    title: "Classic Leather Jacket",
    description:
      "Stylish and durable, this leather jacket is the perfect addition to your wardrobe.",
    price: "$250",
    image: "https://picsum.photos/800/450?random=12",
    stock: 3,
  },
  {
    category: "Home",
    title: "Luxury Sofa Set",
    description:
      "A comfortable and stylish sofa set that complements any living room.",
    price: "$2000",
    image: "https://picsum.photos/800/450?random=45",
    stock: 2,
  },
];

const StyledTypography = styled(Typography)({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const TitleTypography = styled(Typography)(({ theme }) => ({
  position: "relative",
  textDecoration: "none",
  "&:hover": { cursor: "pointer" },
  "& .arrow": {
    visibility: "hidden",
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
  "&:hover .arrow": {
    visibility: "visible",
    opacity: 0.7,
  },
  "&:focus-visible": {
    outline: "3px solid",
    outlineColor: "hsla(210, 98%, 48%, 0.5)",
    outlineOffset: "3px",
    borderRadius: "8px",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    width: 0,
    height: "1px",
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.text.primary,
    opacity: 0.3,
    transition: "width 0.3s ease, opacity 0.3s ease",
  },
  "&:hover::before": {
    width: "100%",
  },
}));

function ProductDetails({
  product,
}: {
  product: { price: string; stock: number };
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
        {product.price}
      </Typography>
      <Typography
        variant="caption"
        sx={{ color: product.stock > 0 ? "green" : "red" }}
      >
        {product.stock > 0 ? "In Stock" : "Out of Stock"}
      </Typography>
    </Box>
  );
}

export default function LatestProducts() {
  const [focusedCardIndex, setFocusedCardIndex] = React.useState<number | null>(
    null
  );

  const handleFocus = (index: number) => {
    setFocusedCardIndex(index);
  };

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  return (
    <div>
      <Typography variant="h2" gutterBottom>
        Latest Products
      </Typography>
      <Grid container spacing={8} columns={12} sx={{ my: 4 }}>
        {productInfo.map((product, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 1,
                height: "100%",
              }}
            >
              <Typography gutterBottom variant="caption" component="div">
                {product.category}
              </Typography>
              <TitleTypography
                gutterBottom
                variant="h6"
                onFocus={() => handleFocus(index)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === index ? "Mui-focused" : ""}
              >
                {product.title}
                <NavigateNextRoundedIcon
                  className="arrow"
                  sx={{ fontSize: "1rem" }}
                />
              </TitleTypography>
              <StyledTypography
                variant="body2"
                color="text.secondary"
                gutterBottom
              >
                {product.description}
              </StyledTypography>
              <img
                src={product.image}
                alt={product.title}
                style={{ width: "100%", height: "auto", borderRadius: "8px" }}
              />
              <ProductDetails product={product} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
        <Pagination
          hidePrevButton
          hideNextButton
          count={10}
          boundaryCount={10}
        />
      </Box>
    </div>
  );
}
